// reimport self so that we could call mocked functions within one module,
// see https://stackoverflow.com/questions/51269431/jest-mock-inner-function/55193363#55193363
import * as moduleReimported from "./cookie";

import { CE_PARAMS, COOKIE_EXPIRY_TIME } from "../constants";

export const cookieOptions = ({ maxAge = COOKIE_EXPIRY_TIME, expires = null }) => {
    expires = expires ? `;expires=${expires.toUTCString()}` : "";
    const domain = process.env.REACT_APP_COOKIE_DOMAIN ? `;domain=${process.env.REACT_APP_COOKIE_DOMAIN}` : "";
    return `${process.env.REACT_APP_ADDITIONAL_COOKIE_PARAMS}${domain}${expires};max-age=${maxAge}`;
};

export const setCookie = (key, value, options = {}) => {
    // WARNING: if you change this function, change the matching one in src/index.js as well!
    // it's duplicated there to save some space in the small bundle
    const cookieval = value ? encodeURIComponent(value) : "";
    const cookieparams = cookieOptions(options);
    document.cookie = `${key}=${cookieval}${cookieparams}`;
};

export const removeCookie = (key, options = {}) => {
    options = {
        ...options,
        maxAge: 0,
        expires: new Date(1970, 1, 1, 0, 0, 1)
    };
    setCookie(key, "", options);
};

export const clearAllCookies = () => {
    while (document.cookie) {
        const key = document.cookie.split("=")[0];
        removeCookie(key);
        removeCookie(key, { path: "/", domain: ".kpn.com" });
    }
};

export const getCookieConfig = (cookieName) => {
    const cookieValue = getCookie(cookieName);
    return cookieValue ? JSON.parse(cookieValue) : {};
};

export const setCookieConfig = (cookieName, config) => {
    setCookie(cookieName, JSON.stringify(config));
};

export const getCookie = (cookieName) => {
    const _regex = new RegExp(`(^| )${cookieName}=([^;]+)`);
    const match = document.cookie.match(_regex);
    const cookieValue = match == null ? "" : match[2].replace(/"/g, "");
    try {
        return decodeURIComponent(cookieValue);
    } catch {

    }
    return cookieValue;
};

export const getCookieParam = (param) => {
    const value = getCookieConfig(CE_PARAMS)[param];
    if (value === undefined) {
        return null;
    }
    return value;
};

export const setCookieParam = (param, value) => {
    let cookie = getCookieConfig(CE_PARAMS);
    cookie[param] = value;
    setCookieConfig(CE_PARAMS, cookie);
};

export const removeCookieParam = param => {
    const cookie = getCookieConfig(CE_PARAMS);
    if (cookie.hasOwnProperty(param)) {
        delete cookie[param];
        setCookieConfig(CE_PARAMS, cookie);
    }
};

export const extendCookiesExpiration = (keys) => {
    keys.forEach(function (key) {
        // calling a function of this same module but reimported, for the sake of unit test mocks
        let value = moduleReimported.getCookie(key);
        if (value !== undefined) {
            moduleReimported.setCookie(key, value);
        }
    });
};
