import { APP_ID } from "./constants";
import { getCookie } from "./helpers/cookie";
import { sessionExists } from "./helpers/session.js";

// make sure other chunks are loaded from same domain as the current chunk
if (document.currentScript) {
    const url = new URL(document.currentScript.src);

    /*
    * We are serving chunks from 2 possible url "patterns" for now
    * - the assistent subdomains:
    *   - Examples: assistent.kpn.com, assistent.acc.kpn.com
    *   - In this case, we take the webpack path (starting with ./static/.*), remove the starting dot ".",
    *     then prepend it with the script's host, effective making the path: assistent.kpn.com/static/.*
    * - the main kpn.com domains followed by /assistent:
    *   - Examples: kpn.com/assistent, kpn-acc.open.kpn.com/assistent
    *   - In this case, we take the webpack path (starting with ./static/.*), replace the starting dot "." with
    *     /assistent, resulting in /static/assistent/.*, then prepend it with the script's host, effective making
    *     the path: kpn.com/assistent/static/.*
    */
    if (url.host.startsWith("assistent.")) {
        __webpack_public_path__ = (url.origin + __webpack_public_path__.replace(/^\./, '')); // eslint-disable-line no-undef
    } else if (url.pathname.startsWith("/assistent/")) {
        __webpack_public_path__ = (url.origin + __webpack_public_path__.replace(/^\./, '/assistent')); // eslint-disable-line no-undef
    }
}

export let appImported = false;
let chatApp;
global.chatAppLoaded = false;
window.adobeDataLayer = window.adobeDataLayer || [];
const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN || ".kpn.com";

const allowedURLCEParams = [
    'market-type',
    'product-type',
    'intent-id',
    'n-concepts',
    'starting-platform',
    'journey',
    'sub-journey',
    'starting-question',
    'mix-env',
    'callback-id',
    'language',
];
const dash2Under = paramName => paramName.replace(/-/g, "_");
const under2Dash = paramName => paramName.replace(/_/g, "-");

const sanitize = (string) => {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
        "`": '&grave;',
    };
    const reg = /[&<>"'/`]/ig;
    return string.replace(reg, (match) => (map[match]));
};

export const loadApp = (isNewChat=false) => {
    if (!appImported) {
        chatApp = import("./app.js");
        appImported = true;
    }
    return chatApp.then((app) => {
        if (global.chatAppLoaded === false) {
            app.load(isNewChat);
            global.chatAppLoaded = true;
        }
    });
};

export const setCookie = (key, value) => {
    // WARNING: if you change this function, change the matching one in src/helpers/cookie.js as well!
    // it's duplicated here to save some space in the small bundle
    // The max-age is set manually here to not import any constants
    const cookieval = encodeURIComponent(JSON.stringify(value));
    const cookieparams = process.env.REACT_APP_ADDITIONAL_COOKIE_PARAMS || "";
    const domain = process.env.REACT_APP_COOKIE_DOMAIN ? `;domain=${process.env.REACT_APP_COOKIE_DOMAIN}` : "";
    document.cookie = `${key}=${cookieval}${cookieparams}${domain};max-age=5400`;
};

const isApp = () => /(^|'|;\s*)mijnkpnapp=1($|'|;)/.test(document.cookie);

export const getQueryParams = () => {
    let queryParams = {};
    const regex = new RegExp("([a-zA-Z-]+)=([^#&]+)", "g");
    const result = sanitize(window.location.search).match(regex);
    if (result) {
        result.forEach(param => {
            const [key, value] = param.split("=");
            queryParams[key] = decodeURIComponent(value);
        });
    }
    return queryParams;
};

const makeCEParams = (skill, urlParams) => {
    const getCookieDomainZone = () => {
        const match = /(\.com|\.org)$/.exec(cookieDomain);
        return match ? match[1] : cookieDomain;
    };

    // extract known keys from url params
    const CEParams = {};
    for (const param of allowedURLCEParams) {
        const key = dash2Under(param);
        CEParams[key] = urlParams[key];
    }

    return  {
        ...CEParams,
        skill,
        language: CEParams.language || getCookie('mijnkpnapp_locale'),
        browser_id: window.adobeId,
        has_webview: isApp(),       // a flag we send to Mix
        login_from_start: isApp(),
        user_agent: navigator.userAgent,
        origin: window.location.href,
        referrer: document.referrer,
        cookie_domain_zone: getCookieDomainZone()
    };

};

export const ceStartChat = (skill, params = {}) => {
    if (!skill) {
        console.log('Skill parameter is required to start a session...');
        return;
    }

    if (global.chatAppLoaded) {
        window.ce.maximize();
        return;
    }

    // This is WebView in the app and not in "assistent" webview
    if (isApp() && !window.CE_STANDALONE) {
        // Create query string from non-null values to avoid passing "null" or "undefined" as query param
        const queryString = Object.entries({ skill, ...params })
            .filter(([k, v]) => v != null)
            .map(([k, v]) => `${under2Dash(k)}=${v}`) // convert _ to - for query param
            .join('&');

        window.MKWebViewBridge.sendEvent({
            app: APP_ID,
            type: 'MK_OPEN_WEBVIEW',
            payload: {
                url: `${process.env.REACT_APP_MK_APP_WEBVIEW_LOCATION}/?${queryString}`
            }
        });
        return;
    }

    setCookie("CE_PARAMS", makeCEParams(skill, params));
    loadApp(true);
};

export const ceEnableChatButton = (skill, params = {}) => {
    import('./components/ChatStartButton').then((module) => {
        module.default(skill, params);
    });
};

export const whenReady = function () {
    if (isApp() && !window.CE_STANDALONE) {
        // when in the app, don't load the chat on KPN pages, just on the 'Hulp' tab
        // unless in "assistent" webview
        return;
    }
    let browser_id = undefined;
    try {
        browser_id = window._satellite.getVisitorId().getMarketingCloudVisitorID();
    } catch (err) {
        // we don't expect the Adobe Layer to be everywhere, so it's fine if this fails
        console.error(err.message);
    }
    // global vars consumed by Mopinion form(s)
    // if you ever try to move these:
    // make sure these variables are also available when a user refreshes the page or moves to another page!
    window.adobeId = browser_id;
    return sessionExists().then(active => {
        if (active) {
            console.log("active conversation found, loading app");
            loadApp();
        } else {
            const url_params = getQueryParams();
            const get_param = (key) => url_params[key] ? url_params[key] : null;
            const CEParams = {};
            for (const param of allowedURLCEParams) {
                const CEKey = dash2Under(param);
                CEParams[CEKey] = get_param(param);
            }
            ceStartChat(get_param('skill'), CEParams);
        }
    });
};

window.ceStartChat = ceStartChat;
window.ce = {
    startChat: ceStartChat,
    enableChatButton: ceEnableChatButton,
};

if (document.readyState === 'complete') {
    whenReady();
} else {
    window.addEventListener("load", whenReady);
}
