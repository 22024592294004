import blinking from "../images/blinking";
import happy from "../images/happy";
import jump from "../images/jump";
import sad from "../images/sad";
import typing from "../images/typing";
import typingIntro from "../images/typing-intro";
import typingMiddle from "../images/typing-middle";
import typingOutro from "../images/typing-outro";
import waiting from "../images/waiting";

/**
 * @typedef {{Play: string, Delay: string, Loop: string}} AnimationStepType
 */
export const AnimationStepType = {
    Play: "play",
    Delay: "delay",
    Loop: "loop",
};

export const AnimationData = {
    idle: typing,
    agentTyping: typing,
    happy: happy,
    compassion: sad,
    waiting: waiting,
    blinking: blinking,
    jump: jump,
    typingIntro: typingIntro,
    typingMiddle: typingMiddle,
    typingOutro: typingOutro,
};

export const AnimationStepName = {
    idle: "idle",
    agentTyping: "agentTyping",
    happy: "happy",
    compassion: "compassion",
    waiting: "waiting",
    blinking: "blinking",
    jump: "jump",
    typingIntro: "typingIntro",
    typingMiddle: "typingMiddle",
    typingOutro: "typingOutro",
};

export const AnimationCategory = {
    idle: "idle",
    agentTyping: "agentTyping",
    agentNotTyping: "agentNotTyping",
};

/**
 * AnimationOnCancel object represents the possible cancellation behaviors for an animation.
 * 
 * @typedef {object} AnimationOnCancel
 * @property {string} End - The animation will end immediately when cancelled.
 * @property {string} Next - The next animation will be played completely when cancelled.
 */
export const AnimationOnCancel = {
    End: "end",
    Next: "next",
};

export const ADD_ANIMATION = "ADD_ANIMATION";
export const REMOVE_ANIMATION = "REMOVE_ANIMATION";
export const REMOVE_ANIMATION_STEP = "REMOVE_ANIMATION_STEP";
export const ADD_DELAY_REFERENCE = "ADD_DELAY_REFERENCE";
export const RESET_ANIMATION_QUEUE = "RESET_ANIMATION_QUEUE";
export const REMOVE_AGENT_TYPING_ANIMATION = "REMOVE_AGENT_TYPING_ANIMATION";

